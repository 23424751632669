import React from 'react'
import Point from '../../components/Point'
import './point.css';

export default function Index () {

  return (
    <div className='pointValue'>
    <Point />
    </div>
  )
}