import React, { useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { addSale } from '../../store/productsSlice'; 

const productoSchema = yup.object().shape({
  serie: yup.string().required().length(9),
  modelo: yup.string().required(),
  montoEquipo: yup.number().required(),
  montoRefaccion: yup.number().required(),
});

const schema = yup.object().shape({
  productos: yup.array().of(productoSchema),
  fechaVenta: yup.date().required(),
  factura: yup.mixed().required(),
});

export default function EDMAddSale() {
  const { register, control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productos',
  });
  const [fechaVenta, setFechaVenta] = useState(null);
  const [factura, setFactura] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    data.fechaVenta = fechaVenta;
    data.factura = factura;
    dispatch(addSale(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => (
        <div key={item.id}>
          <input name={`productos[${index}].serie`} ref={register} placeholder="No. de serie" />
          <input name={`productos[${index}].modelo`} ref={register} placeholder="Modelo" />
          <input name={`productos[${index}].montoEquipo`} ref={register} placeholder="Monto del equipo" />
          <input name={`productos[${index}].montoRefaccion`} ref={register} placeholder="Monto de Refacción" />
          <button type="button" onClick={() => remove(index)}>Eliminar</button>
        </div>
      ))}
      <button type="button" onClick={() => append({ serie: '', modelo: '', montoEquipo: '', montoRefaccion: '' })}>
        Agregar más productos
      </button>
      <input name="fechaVenta" ref={register} type="date" placeholder="Fecha Venta" onChange={(e) => setFechaVenta(e.target.value)} />
      <input name="factura" ref={register} type="file" placeholder="Adjuntar factura" onChange={(e) => setFactura(e.target.files[0])} />
      <button type="submit">Enviar</button>
    </form>
  );
}