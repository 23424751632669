import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Button, Alert } from "react-bootstrap";
import Context from "../../context/UserContext";
import api from "services/settings";

export default function Utility() {
  const { token } = React.useContext(Context);
  const setting_id = 2;
  const [utility, setUtility] = useState("");
  const [value, setValue] = useState("");
  const [alert, setAlert] = React.useState({
    show: false,
    color: "danger",
    message: "",
  });

  useEffect(() => {
    api.settings_show(token, setting_id).then(({ value }) => {
      setUtility(value);
    });
  }, []);
  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  const handleClick = () => {
    if (isPositiveInteger(value)) {
      api.settings_update(token, setting_id, value).then(({data:{value}}) => {        
        setAlert((prev) => ({
          ...prev,
          color: "success",
          show: true,
          message: "El valor fue actualizado.",
        }));
        setValue("");
        setUtility(value);
      });
    } else {
      setAlert((prev) => ({
        ...prev,
        color: "danger",
        show: true,
        message: "Debes registar un número entero positivo entre 0 y 100.",
      }));
    }
  };

  const isPositiveInteger = (str) => {
    if (typeof str !== "string") {
      return false;
    }
    const num = Number(str);
    if (Number.isInteger(num) && num >= 0 && num <= 100) {
      return true;
    }
    return false;
  };

  return (
    <Container>
        
      <Row className="justify-content-md-center">
        <Col xs={6} sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Porcentaje de utilidad: </Form.Label>
            <Form.Control disabled value={`${utility} % `} />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center">
        <Col xs={6} sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Actualizar Valor: </Form.Label>
            <Form.Control value={value} onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={6} sm={3}>
          <Button variant="primary" onClick={() => handleClick()}>
            Actualizar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          {alert.show && (
            <Alert
              variant={alert.color}
              className="mt-4"
              onClose={() => setAlert((prev) => ({ ...prev, show: false }))}
              dismissible
            >
              <p>{alert.message}</p>
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
}
