import React, { useState, useEffect, useRef } from "react";
import useProducts from "../../hooks/useProducts";
import useUser from "../../hooks/useUser";
import { Toaster } from "react-hot-toast";
import Ventas from "../../components/Ventas";
import Swal from "sweetalert2";
import { subDays, format } from "date-fns";

const templete = {
  NoSerie: "",
  SelectedEquipo: "",
  PuntosSave: "",
  ImagenModelo: "",
  selectedFile: "",
  Participacion: false,
  Porcentaje: "",
  Monto: "",
  monto_producto: "",
  monto_refacciones: "",
};

export default function HomePage() {
  const {
    successs,
    success,
    message,
    error,
    Productos,
    Status,
    setProductos,
    ProductosAddService,
    ProductosGetService,
  } = useProducts();
  const { tokenFull, IdUser, Dealer } = useUser();
  const [Fecha, setFecha] = useState("");
  const [selectedFile, setselectedFile] = useState("");
  const [Arreglo, setArreglo] = useState([templete]);
  const image = useRef(null);
  const [Boton, setBoton] = useState(true);
  const [invalidInputs, setInvalidInputs] = React.useState({});

  const changeHandler = (event) => {
    setselectedFile(event.target.files[0]);
    //setisFilePicked(true);
  };
  const NuevaSerie = () => {
    Arreglo.forEach((element) => {
      if (element.NoSerie === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#3b82f6",
          text: "Agregar numero de serie valido",
        });
      } else if (element.NoSerie !== "") {
        setArreglo([...Arreglo, templete]);
      }
    });
  };

  const ProductosAgregados = () => {
    const validacion = Arreglo.reduce((acumulador, elemento, index) => {
      const esValidoProducto =
        elemento.monto_producto !== undefined &&
        elemento.monto_producto !== "" &&
        /^\d+(\.\d{1,2})?$/.test(elemento.monto_producto) &&
        elemento.monto_producto >= 0;

      const esValidoRefacciones =
        elemento.monto_refacciones !== undefined &&
        elemento.monto_refacciones !== "" &&
        /^\d+(\.\d{1,2})?$/.test(elemento.monto_refacciones) &&
        elemento.monto_refacciones >= 0;

      const esValidoNoSerie =
        elemento.NoSerie !== undefined &&
        elemento.NoSerie !== "" &&
        elemento.NoSerie.length === 9 &&
        /^\d{9}$/.test(elemento.NoSerie);
        
        
      console.log(elemento.NoSerie, "elemento.NoSerie");
      console.log(elemento.NoSerie.length, "elemento.NoSerie.length");

      console.log(typeof(elemento.NoSerie),elemento.NoSerie.length === 9, "elemnto");
      console.log(esValidoNoSerie, "esValidoNoSerie");

      if (!esValidoProducto || !esValidoRefacciones || !esValidoNoSerie) {
        setInvalidInputs((prevState) => {
          return {
            ...prevState,
            [index]: {
              ...prevState[index],
              ...(!esValidoProducto ? { monto_producto: true } : {}),
              ...(!esValidoRefacciones ? { monto_refacciones: true } : {}),
              ...(!esValidoNoSerie ? { NoSerie: true } : {}),
            },
          };
        });
      }

      return (
        acumulador && esValidoProducto && esValidoRefacciones && esValidoNoSerie
      );
    }, true);
console.log(validacion, "validacion")
    if (!validacion) {
      mostrarError("Error", "Favor de llenar los campos correctamente");
      return;
    } else {
      const data = new FormData();
      data.append("user_id", IdUser);
      data.append("dealer_id", Dealer);
      data.append("productos", JSON.stringify(Arreglo));
      data.append("fecha", Fecha);
      data.append("factura", selectedFile);

      ProductosAddService(data, tokenFull);

      Swal.fire({
        html: "Espere por favor...",
        timer: 2000,
        confirmButtonColor: "#3b82f6",
        timerProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });

      setArreglo([templete]);
      setselectedFile("");
      image.current.value = "";
      setFecha("");
      Arreglo.forEach((element) => {
        element.NoSerie = "";
        element.SelectedEquipo = "";
        element.PuntosSave = "";
        element.monto_producto = "";
        element.monto_refacciones = "";
      });
      setProductos([]);
    }
  }, [message, success]);

  const handleKeyDown = (e) => {
    // Prevenir la escritura manual en el campo de entrada de fecha
    e.preventDefault();
  };

  const mostrarError = (titulo, mensaje) => {
    Swal.fire({
      icon: "error",
      title: titulo,
      confirmButtonColor: "#3b82f6",
      text: mensaje,
    });
  };

  const obtenerFechaMinima = () => {
    const fechaActual = new Date();
    const fechaAjustada = subDays(fechaActual, 39);
    return format(fechaAjustada, "yyyy-MM-dd");
  };

  const fechaMinima = obtenerFechaMinima();

  return (
    <>
      <Toaster />
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <h2 className="titulo2">Registro de productos</h2>
          </div>
        </div>
        <br />
        {Arreglo.map((item, index) => {
          return (
            <Ventas
              key={index}
              item={item}
              error={error}
              successs={successs}
              message={message}
              index={index}
              Arreglo={Arreglo}
              Productos={Productos}
              Status={Status}
              setArreglo={setArreglo}
              setProductos={setProductos}
              ProductosGetService={ProductosGetService}
              setBoton={setBoton}
              invalidInputs={invalidInputs}
              setInvalidInputs={setInvalidInputs}
            />
          );
        })}
        <div className="row">
          <div className="col-md-6" />
          <div className="col-md-6">
            {Boton ? (
              <>
                <div className="col-md-12 d-flex justify-content-end">
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={() => NuevaSerie()}
                  >
                    + Agregar mas productos
                  </button>
                </div>
              </>
            ) : (
              ""
            )}

            {/*             <h4>
              El sistema solo acepta facturas con fecha de venta a partir del 1
              de abril de 2024
            </h4> */}
            <div className="col-md-12">
              <label>Fecha Venta</label>

              <input
                type="date"
                min={fechaMinima}
                id="fecha"
                className="form-control"
                value={Fecha}
                onChange={(e) => setFecha(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <br />
            </div>

            <div className="col-md-12">
              <label>Adjuntar Factura</label>
              <input
                type={"file"}
                className="form-control"
                accept="image/*,.pdf"
                onChange={changeHandler}
                ref={image}
              />
              <br />
            </div>
            <div className="col-md-12">
              <div className="d-grid gap-2">
                <button
                  className="btn btn-primary"
                  onClick={() => ProductosAgregados()}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}
