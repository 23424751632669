 const ENDPOINT = process.env.REACT_APP_API_URL;

 async function settings_show( token, id ) {
    return await fetch(`${ENDPOINT}/settings/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }).then(res => {
      if (!res.ok) throw new Error('Response is NOT ok')
      return res.json()
    });  
  }
 
 async function  settings_update( token, id, value ) {
    return await fetch(`${ENDPOINT}/settings/${id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify({ value})
    }).then(res => {
      if (!res.ok) throw new Error('Response is NOT ok')
      return res.json()
    });  
  }


  export default { settings_show, settings_update } ;