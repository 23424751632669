import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import Context from "../../context/UserContext";
import api from "services/settings";

export default function PointValue() {
  const { token } = React.useContext(Context);
  const setting_id = 1;
  const [point, setPoint] = useState("");
  const [value, setValue] = useState("");
  const [alert, setAlert] = React.useState({
    show: false,
    color: "danger",
    message: "",
  });

  useEffect(() => {
    api.settings_show(token, setting_id).then(({ value }) => {
      console.log(value);
      setPoint(value);
    });
  }, []);
  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  const handleClick = () => {
    if (isPositiveInteger(value)) {
      api
        .settings_update(token, setting_id, value)
        .then(({ data: { value } }) => {
          setAlert((prev) => ({
            ...prev,
            color: "success",
            show: true,
            message: "El valor fue actualizado.",
          }));
          setValue("");
          setPoint(value);
        });
    } else {
      setAlert((prev) => ({
        ...prev,
        color: "danger",
        show: true,
        message: "Debes registar un número entero positivo.",
      }));
    }
  };

  const isPositiveInteger = (str) => {
    if (typeof str !== "string") {
      return false;
    }
    const num = Number(str);
    if (Number.isInteger(num) && num > 0) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <Row className="justify-content-center pt-5">
        <Col>
        <h2 className="point__title">Valor del punto</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card" style={{ width: "22rem", margin: "0 auto", borderRadius: "15px" }}>
            <Card.Body className="point__card">
              <Card.Text>
               
              </Card.Text>

              <Row className="justify-content-center mt-5">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>$1 MXN equivale a: </Form.Label>
                    <Form.Control disabled value={`${point} puntos `} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-5 justify-content-md-center">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Actualizar Valor: </Form.Label>
                    <Form.Control value={value} onChange={handleChange} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="justify-content-md-center">
                <Col>
                  <Button variant="primary" onClick={() => handleClick()}>
                    Actualizar
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  {alert.show && (
                    <Alert
                      variant={alert.color}
                      className="mt-4"
                      onClose={() =>
                        setAlert((prev) => ({ ...prev, show: false }))
                      }
                      dismissible
                    >
                      <p>{alert.message}</p>
                    </Alert>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
