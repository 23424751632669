import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/Login/login.css";
import { Container } from "react-bootstrap";
import Logo from "components/Logo";
import useRecuperar from '../../hooks/useRecuperar';
import Swal from "sweetalert2";

export default function Recuperar() {
    const { success, message, error, RecuperarPostService } = useRecuperar();
    const [Correo, setCorreo] = useState("");
    //const [, navigate] = useLocation();
    let navigate = useNavigate();

    const ProductosAgregados = () => {
        const data = new FormData();
        data.append("email", Correo);
        RecuperarPostService(data);

        Swal.fire({
            html: "Espere por favor...",
            timer: 2000,
            confirmButtonColor: "#3b82f6",
            timerProgressBar: true,
        });
    }

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
        }
    }, [message, error])

    useEffect(() => {
        if (success) {
            Swal.fire({
                icon: "success",
                title: "Exito",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
            setCorreo('');
        }
    }, [message, success])

    return (
        <React.Fragment>
            <div className="login__background">
                <Container>
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="login">
                            <Logo className="d-block img-fluid w-75 mx-auto" />
                            <h3 className="titulo_login">Recuperar contraseña</h3>
                            <ul className="login__links">
                                <li><Link to="/login"> Inicio de sesión</Link> </li>
                                <li><Link to="/vendedor-especializado"> Registro </Link> </li>
                            </ul>

                            <input
                                className="login__input"
                                placeholder="Correo eléctronico"
                                onChange={(e) => setCorreo(e.target.value)}
                                value={Correo}
                            />
                            <button type="button" className="btn-primary d-block mx-auto my-3" onClick={(() => ProductosAgregados())}>Recuperar</button>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}
