import Utility from 'components/Utility'
import React from 'react'


export default function UtilityPage() {
  return (
    <div>
      <Utility />
    </div>
  )
}
