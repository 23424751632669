import { createSlice } from '@reduxjs/toolkit';

const initialState =  {
   serie : "",
   modelo : "",
   montoEquipo : "",
   montoRefaccion : "",
  };


const productsSlice = createSlice({
  name: 'products',
  initialState: [],
  reducers: {
    addSale: (state, action) => {
      state.push(...action.payload);
    },
  },
});

export const { addSale } = productsSlice.actions;

export default productsSlice.reducer;


