import { configureStore } from '@reduxjs/toolkit'
import reducer from './productsSlice'

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }), 
})
