import React, { useEffect, useState } from "react";
import useProducts from "../../hooks/useProducts";
import useUser from "../../hooks/useUser";
import toast from "react-hot-toast";
import Buscando from "../../assets/images/Banner_serie.png";
import NoEncontrado from "../../assets/images/Banner_no_serie2.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export default function Ventas({
  item,
  index,
  error,
  successs,
  message,
  Arreglo,
  setArreglo,
  setBoton,
  invalidInputs,
  setInvalidInputs,
}) {
  const { Productos, Status, Mensaje, ProductosGetService } = useProducts();
  const { tokenFull, Dealer } = useUser();
  const [Error, setError] = useState(false);
  const ENDPOINT = process.env.REACT_APP_BASE_URL;

//  console.log(invalidInputs, "invalidInputs");

 // console.log(item, "Item");

 console.log(Productos, "Productos");

  const ChangeInfo = (value, name) => {
    const newArreglo = Arreglo.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setArreglo(newArreglo);
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    ChangeInfo(e.target.value, "NoSerie");
    if (e.target.value.length === 9) {
      Arreglo.every((item, i) => {
        if (item.NoSerie === e.target.value) {
          setError(true);
          toast.error("El número de serie ya existe");
          setBoton(false);
          return false;
        } else {
          const data = new FormData();
          data.append("serie", e.target.value);
          data.append("num_dis", Dealer);
          setBoton(true);
          ProductosGetService(data, tokenFull);
          setError(false);
          return true;
        }
      });
    } else {
      //item.modelo == '';
      // Productos[0].modelo = '';
      // Productos[0].puntos = '';
      // Productos[0].imagen = '';
      // Productos[0].SelectedEquipo = '';
      // Productos[0].id = '';
    }
  };

  useEffect(() => {
    if (Productos.length > 0) {
      if (Productos[0] === false) {
        Arreglo.slice(Arreglo.length - 1, 1);
        setArreglo(Arreglo);
      } else {
        const { id, puntos } = Productos[0];
        const newArreglo = Arreglo.map((item, i) => {
          if (i === index) {
            return { ...item, SelectedEquipo: id, PuntosSave: puntos };
          } else {
            return item;
          }
        });
        setArreglo(newArreglo);
      }
    }
  }, [Productos]);

  useEffect(() => {
    if (error) {
      setError(true);
    }
  }, [message, error]);

  useEffect(() => {
    if (successs) {
      setError(false);
    }
  }, [message, successs]);

  const EliminarSerie = (id) => {
    const items = Arreglo.filter((item, index) => index !== id);
    setArreglo(items);
  };

  useEffect(() => {
    if (Status === "disponible") {
      setBoton(true);
    } else {
      setBoton(false);
    }
  }, [Status]);

  const handleInputChange = (value, field) => {
    ChangeInfo(value, field);
    setInvalidInputs((prevState) => {
      return {
        ...prevState,
        [index]: {
          ...prevState[index],
          [field]: false,
        },
      };
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          {item.NoSerie !== "" ? (
            Productos[0] ? (
              Productos[0].imagen === "" ? (
                <img className="img-fluid " src={Buscando} alt="teser left" />
              ) : (
                <img
                  className="img-fluid "
                  src={
                    ENDPOINT +
                    "/images/productos/" +
                    (Productos[0] ? Productos[0].imagen : "")
                  }
                  alt="producto"
                />
              )
            ) : (
              <img className="img-fluid " src={NoEncontrado} alt="teser left" />
            )
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6">
          <div className="row">
            {index >= 1 ? (
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-danger btn-s"
                  onClick={() => EliminarSerie(index)}
                >
                  X
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-12">
              <label>No. de serie ( 9 números )</label>
              <input
                type="text"
                className={Error ? "form-control is-invalid" : "form-control"}
                maxLength="9"
                value={item.NoSerie}
                onChange={(e) => handleOnChange(e)}
              />
              {(Status === "disponible" && (
                <span className="badge bg-success">Producto Disponible</span>
              )) ||
                (Status === "ocupado" && (
                  <span className="badge bg-danger">
                    Producto ya registrado por otro vendedor
                  </span>
                )) ||
                (Status === "no disponible" && (
                  <span className="badge bg-warning">
                    Producto No Disponible
                  </span>
                )) ||
                (Status ===
                  "producto no encontrado en la familia economica" && (
                  <span className="badge bg-danger">{Mensaje}</span>
                ))}

              <span className="text-danger">
                {item &&
                  !item.NoSerie &&
                  "El campo es requerido."}
                {item &&
                  item.NoSerie &&
                  item.NoSerie.length !== 9 &&
                  "El número de serie debe ser de 9 caracteres."}
              </span>
              <br />
            </div>
            <div className="col-md-12">
              <label>Modelo</label>
              <input
                type="text"
                className="form-control"
                disabled={true}
                value={Productos[0] ? Productos[0].modelo : ""}
                onChange={(e) => ChangeInfo(e.target.value, "SelectedEquipo")}
              />
              <br />
            </div>

            <div className="col-md-12">
              <label>Monto Equipo</label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Monto del equipo"
                  type="text"
                  value={item.monto_producto}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "monto_producto")
                  }
                  style={
                    invalidInputs[index] && invalidInputs[index].monto_producto
                      ? { borderColor: "red" }
                      : {}
                  }
                />
              </InputGroup>

              <span className="text-danger">
                {invalidInputs[index] &&
                  invalidInputs[index].monto_producto &&
                  "El campo es requerido."}
                {item.monto_producto &&
                  item.monto_producto < 1 &&
                  "El monto del equipo es requerido y debe ser mayor a 0."}
                {item.monto_producto &&
                  item.monto_producto &&
                  !/^\d+(\.\d{1,2})?$/.test(item.monto_producto) &&
                  "El monto del equipo debe estar en formato moneda por ejemplo: 10489.00"}
              </span>

              <br />
            </div>

            <div className="col-md-12 mt-3">
              <label>Monto Refacciones</label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Monto del equipo"
                  type="text"
                  value={item.monto_refacciones}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "monto_refacciones")
                  }
                  style={
                    invalidInputs[index] &&
                    invalidInputs[index].monto_refacciones
                      ? { borderColor: "red" }
                      : {}
                  }
                />
              </InputGroup>

              <span className="text-danger">
                {invalidInputs[index] &&
                  invalidInputs[index].monto_refacciones &&
                  "El campo es requerido."}
                {item.monto_refacciones &&
                  item.monto_refacciones < 1 &&
                  "El monto de las refacciones es requerido y debe ser mayor a 0."}
                {item.monto_refacciones &&
                  item.monto_refacciones &&
                  !/^\d+(\.\d{1,2})?$/.test(item.monto_refacciones) &&
                  "El monto de las refacciones debe estar en formato moneda por ejemplo: 10489.00"}
              </span>

              <br />
            </div>

            <input
              type="hidden"
              className="form-control"
              disabled={true}
              value={Productos[0] ? Productos[0].id : ""}
              onChange={(e) => ChangeInfo(e.target.value, "ModeloId")}
            />
            <br />
          </div>
        </div>
        <div className="col-md-12">
          <hr style={{ background: "#ed7100" }} />
        </div>
      </div>
    </>
  );
}
